import React from 'react';
import style from './index.module.css';


function Footer() {
  return (
    <footer className={style.footer}>
        Copyright 2022  |  O. Riastanjung
    </footer>
  )
}

export default Footer